<template>
    <div class="page page-public page-signin">
        <div class="public-wrap">
            <window-setting></window-setting>
            <div class="banner drag">
                <img src="https://resources.oz4.cn/mashang/push_img/banner.png">
            </div>

            <div class="public-container">
                <div class="title ">
                    <font> 登 录 </font>
                </div>
                <Form v-show="type == 'pass'" ref="passForm" :model="passForm"  :rules="passFormValidate" :label-width="0">
                    <FormItem prop="username" >
                        <Input v-model="passForm.username" placeholder="手机号码">
                            <icon-svg icon-class="icon-nickname" slot="prefix" ></icon-svg>
                        </Input>
                    </FormItem>
                    <FormItem prop="password" >
                        <Input v-model="passForm.password" type="password" @keyup.enter.native="signin('passForm')" placeholder="密码">
                            <icon-svg icon-class="icon-privac_open" slot="prefix" ></icon-svg>
                        </Input>
                    </FormItem>
                    <FormItem class="code" prop="captcha_code">
                        <Input v-model="passForm.captcha_code" @keyup.enter.native="signin('passForm')" placeholder="验证码">
                            <icon-svg icon-class="icon-safety" slot="prefix" ></icon-svg>
                        </Input>
                        <img class="code-img" :src="captcha.img" @click="getCodeImg()">
                    </FormItem>
                    <FormItem>
                        <Checkbox v-model="autoSignin" >
                            <span>自动登录</span>
                        </Checkbox>
                        <!-- <router-link class="retrieve pull-right" to="retrieve">忘记密码?</router-link> -->
                    </FormItem>
                    <FormItem class="btn">
                        <Button type="primary" long :loading="loading" @click="signin('passForm')">登 录</Button>
                    </FormItem>
                </Form>


                <Form v-show="type == 'sms'" ref="smsForm" :model="smsForm" :rules="smsFormValidate" :label-width="0">
                    <FormItem  prop="username" >
                        <Input prefix="ios-contact" v-model="smsForm.username" placeholder="手机号码">
                            <icon-svg icon-class="icon-nickname" slot="prefix" ></icon-svg>
                        </Input>
                    </FormItem>
                    <FormItem class="code" prop="code">
                        <Input
                            v-model="smsForm.code"
                            @keyup.enter.native="signin('smsForm')"
                            :maxlength="4"
                            placeholder="验证码">
                                <icon-svg icon-class="icon-safety" slot="prefix" ></icon-svg>
                            <div slot="suffix" >
                                <div class="send-code" v-show="!this.countDown.timer" @click="sendCode()">获取验证码</div>
                                <div class="count-down" v-show="this.countDown.timer">{{this.countDown.count}}秒后失效</div>
                            </div>
                        </Input>
                    </FormItem>
                    <FormItem class="auto" prop="auto">
                        <Checkbox v-model="autoSignin">
                            <span>自动登录</span>
                        </Checkbox>
                        <!-- <router-link class="retrieve pull-right" to="retrieve">忘记密码?</router-link> -->
                    </FormItem>
                    <FormItem class="btn" >
                        <Button type="primary" long :loading="loading" @click="signin('smsForm')">登 录</Button>
                    </FormItem>
                </Form>

                <div class="type">
                    <div class="pull-left">
                        <font>使用</font>
                        <span v-if="type == 'pass'"  @click="switchType('sms')" class="item sms">
                            验证码登录
                        </span>
                        <span v-if="type == 'sms'" @click="switchType('pass')"  class="item sms">
                            密码登录
                        </span>
                    </div>

                    <div class="pull-right" >
                        <font>还没账号？</font>
                        <router-link class="retrieve" to="signup">去注册</router-link>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>

import windowSetting from '@/components/comp-window-setting/window-setting';
export default {
  data() {
    return {
      type: 'pass',
      autoSignin: true,
      loading: false,
      passForm: {
        username: '',
        password: '',
        captcha_code: '',
      },
      passFormValidate: {
        username: [
          { required: true, message: '请输入手机号码！', trigger: 'blur' },
          // { min: 11,max: 11,message: '请输入正确的手机号码！',trigger: 'blur'}
        ],
        password: [
          { required: true, message: '请输入密码！', trigger: 'blur' },
          { min: 6, message: '密码必须为6位以上！', trigger: 'blur' },
        ],
        captcha_code: [
          { required: true, message: '请输入验证码！', trigger: 'blur' },
          { min: 4, message: '验证码必须为4位！', trigger: 'blur' },
        ],
      },
      smsForm: {
        mobile: '',
        code: '',
      },
      smsFormValidate: {
        mobile: [
          { required: true, message: '请输入手机号码！', trigger: 'blur' },
          { min: 11, max: 11, message: '请输入正确的手机号码！', trigger: 'blur' },
        ],
        code: [
          { required: true, message: '请输入验证码！', trigger: 'blur' },
          { min: 4, message: '验证码必须为4位以上！', trigger: 'blur' },
        ],
      },
      countDown: {
        show: true,
        count: '',
        timer: null,
      },
      loginList: {
        username: '',
        password: '',
        vcode: '',
      },
      captchaConfig: 1,
      captcha: {
        id: '',
        img: '',
      },

    };
  },
  computed: {},
  components: {
      windowSetting,
  },
  created() {
    this.getCodeImg();
  },
  mounted() {},
  methods: {
    switchType(type) {
      this.type = type;
      this.$refs.smsForm.resetFields();
      this.$refs.passForm.resetFields();
    },

    // 获取验证码配置
    // getCaptchaConfig() {
    //   this.$api.getCaptchaConfig({
    //   }).then((res) => {
    //     console.log(res);
    //     if (res.code >= 0 && res.data) {
    //       this.captchaConfig = res.data.shop_login;
    //       this.getCodeImg();
    //     }
    //   });
    // },

    // 获取验证码
    getCodeImg() {
      this.$api.getCaptcha({}).then((res) => {
        if (res.code >= 0 && res.data) {
          this.captcha = res.data;
          this.captcha.img = this.captcha.img.replace(/\r\n/g, '');
        }
        if (this.$refs.loadingCover) this.$refs.loadingCover.hide();
      });
    },

    // 获取店铺状态
    getShopStatus() {
      this.$api.sendRequest({
        url: '/shopapi/apply/index',
        success: (res) => {
          const { data } = res;
          if (res.code == 0 && data) {
            if (res.data.procedure == 1) {
              this.$util.redirectTo('/pages/apply/mode', {}, 'reLaunch');
            } else {
              this.$util.redirectTo('/pages/apply/audit', {}, 'reLaunch');
            }
          }
        },
      });
    },
    //登录系统
    signin(name) {
      console.log(this.$util);
      this.$refs[name].validate((valid) => {
        if (valid) {
          this.loading = true;
          const params = this[name];
          params.captcha_id = this.captcha.id;
          
          this.$api.signin(params)
            .then((res) => {
              console.log(res);
              if (res.code >= 0) {
                this.$Message.success('登录成功！');
                this.$util.setStorage('token', res.data.token);
                this.$store.commit('tokenToVuex');

                this.loading = false;
                this.$router.push({
                  name: 'index',
                });
              } else {
                this.$Message.error(res.message);
                this.getCodeImg();
                this.loading = false;
              }
            });

        }
      });
    },

    // 获取店铺信息
		    getBrandInfo() {
      this.$api.getBrandInfo(params)
        .then((res) => {
          console.log(res);
          if (res.code >= 0) {
            this.$store.commit('brandToVuex');
          } else {

          }
        });
    },

    // // 获取验证码
    // sendCode() {
    //     if (!this.countDown.timer) {
    //         this.$refs.smsForm.validateField('username', valid => {
    //             if (!valid) {
    //             console.log('发送');

    //                 let this = this;
    //                 this.$api
    //                 .sendCode({
    //                     username: this.smsForm.username,
    //                 })
    //                 .then(function(res) {
    //                     if (res.code == '200') {
    //                         this.$Message.success('发送成功');
    //                         this.sendCodeCountDown();
    //                     } else {
    //                         this.$Message.error(res.msg);
    //                     }
    //                 });


    //             }
    //         });
    //     }
    // },

    // sendCodeCountDown(){
    //         // 倒计时
    //         const TIME_COUNT = 60;
    //         this.countDown.count = TIME_COUNT;
    //         this.countDown.show = false;

    //         this.countDown.timer = setInterval(() => {
    //             if (this.countDown.count > 0 && this.countDown.count <= TIME_COUNT) {
    //                 this.countDown.count--;
    //             } else {
    //                 this.countDown.show = true;
    //                 clearInterval(this.countDown.timer);
    //                 this.countDown.timer = null;
    //             }
    //         }, 1000);
    // }
  },
};
</script>

<style>
    @import url("./css/public.css");
</style>
