<template>
    <div class="comp-window-setting no-drag" v-if="isElectron">
        <div class="box-set no-drag" >
          <div @click="resize('minimize')" class="no-drag">
            <icon-svg icon-class="icon-suoxiao"></icon-svg>
          </div>
          <div @click="resize('maximize')"  class="no-drag" v-if="isPush">
            <icon-svg icon-class="icon-kuangxuan"></icon-svg>
          </div>
          <div @click="resize('close')" class="no-drag">
            <icon-svg icon-class="icon-guanbi"></icon-svg>
          </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'windowSetting',
    data() {
        return {
            isPush:this.$route.name == 'push' ?  true : false,
            isElectron:false
        };
    },
    created(){
      var userAgent = navigator.userAgent.toLowerCase()
      if ( userAgent.indexOf('electron') > -1 ){
        this.isElectron = true
      }else {
        this.isElectron = false
      }
    },
    mounted() {
        
    },
    methods: {
        resize(name){
          let data = {
            'type':'operate',
            'name':name
          }
          switch (name) {
            case 'minimize':
              this.$window.set.resize(data);
              break;
            case 'maximize':
              this.$window.set.resize(data);
              break;
            case 'close':
              if(this.isPush){
                this.$Modal.confirm({
                      'title': '确认关闭软件吗？',
                      'onOk': res => {
                          this.$window.set.resize(data);
                      },
                      'onCancel': res => {
                          
                      }
                })
              }else {
                this.$window.set.resize(data);
              }
              break;
            default:
              break;
          }
        },
    },
};
</script>

<style lang="scss" scoped>

.box-set {
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 99999;
  display: flex;
}
.box-set .icon {
  padding: 6px;
  cursor: pointer;
  
}
.box-set .icon:hover {
  color: #999;
}
</style>